<template>
  <div class="database-manage">
    <div class="tab">
      <div v-for="(item, index) in tab" :key="index" :class="{ 'active': index == activeIndex }"
        @click="changeType(index, item)">
        <i class="iconfont icon-fuzhi" :class="[index == 0 ? 'icon-fuzhi' : 'icon-kaoshi']"></i>
        {{ item.name }}
      </div>
    </div>
    <div class="inner-content">
      <router-view />
      <!-- <question-management v-if="activeIndex==0"></question-management> -->
    </div>

  </div>
</template>

<script>

export default {
  name: 'DatabaseManage',
  data() {
    return {
      user: {},
      tab: [
        { name: '试题管理', path: '/admin/database-manage/questions' },
        { name: '题库统计', path: '/admin/database-manage/questionStatic' },
        { name: '自动组卷', path: '/admin/database-manage/autoVolumes' },
        { name: '组卷记录', path: '/admin/database-manage/volumesRecords' },
        // { name: '管理知识点', path: '/admin/database-manage/manage-knowledge' },
      ],
      activeIndex: 0,
      examList: [],
      total: 0,
      current: 1,
      pageSize: 5,
      treeData: [],

    };
  },

  created() {
    let path = this.$route.path;
    if (path.includes('questions')) {
      this.activeIndex = 0
    }
    if (path.includes('questionStatic')) {
      this.activeIndex = 1
    }
    if (path.includes('autoVolumes')) {
      this.activeIndex = 2
    }
    if (path.includes('volumesRecords')) {
      this.activeIndex = 3
    }
    if (path.includes('manage-knowledge')) {
      this.activeIndex = 4
    }
  },
  methods: {
    go(index, item) {
      this.activeIndex = index;
      this.$router.push(item.path);
    },
    changeType(index,item) {
      if (this.activeIndex == index) return;
      this.activeIndex = index;
      this.$router.push(item.path);
    },

  },
};
</script>

<style scoped lang="scss">
.database-manage {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  margin-top: 20px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  height: calc(100% - 20px);



  .tab {
    height: 48px;
    margin: 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }

      &:hover {
        color: #3bafda;
      }
    }

    .active {
      border-bottom: 3px solid #3bafda;
      color: #3bafda;
    }
  }

  .inner-content {
    width: 100%;
    overflow: hidden;
  }


}
</style>
